import { useCohadoMapDrag } from "./useCohadoMapDrag";

export const CohadoMapModal = () => {
  useCohadoMapDrag();
  return (
    <div
      className="modal fade showmap"
      id="showmap"
      data-bs-keyboard="true"
      tabIndex="-1"
      aria-labelledby="showmap"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div
          className="modal-content"
          style={{
            border: 0,
            backgroundColor: "transparent",
            overflow: "visible",
          }}
        >
          <div
            style={{
              backgroundColor: "transparent",
              overflow: "visible",
              position: "relative",
              touchAction: "none",
            }}
            className="modal-body d-flex justify-content-center"
          >
            <div
              id="cohadomap"
              className="map-drag"
              style={{
                flex: "none",
                position: "relative",
                width: "80vh",
                height: "fit-content",
              }}
            >
              <img
                id="mapImage"
                style={{
                  position: "relative",
                  width: "100%",
                }}
                src="/images/gamemap_paper_05b.png"
                alt=""
              />

              <div
                data-bs-dismiss="modal"
                style={{
                  zIndex: 900,
                  height: "3%",
                  // border: "2px solid yellow",
                  top: "5%",
                  right: "7.5%",
                  width: 50,
                  position: "absolute",
                }}
              >
                {/* <img
                  data-bs-dismiss="modal"
                  style={{
                    height: "100%",
                    display: "block",
                    position: "relative",
                  }}
                  src="/images/CohadoX.png"
                  alt=""
                /> */}
              </div>

              {/* <svg
                style={{
                  zIndex: 900,
                  // border: "2px solid yellow",
                  height: "5%",
                  bottom: "7%",
                  right: "13%",
                  width: 50,
                  position: "absolute",
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-play-btn-fill"
                viewBox="0 0 16 16"
              >
                <path d="M0 12V4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2m6.79-6.907A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z" />
              </svg> */}
              <a
                href="/images/glowmap.png"
                download="cohadomap"
                style={{
                  zIndex: 900,
                  // border: "2px solid yellow",
                  height: "5%",
                  bottom: "7.5%",
                  right: "7%",
                  width: 50,
                  position: "absolute",
                }}
              >
                {/* <a href="/images/glowmap.png" download="cohadomap">
                  <img
                    style={{
                      height: "100%",
                      display: "block",
                      position: "relative",
                      marginBottom: "25%",
                    }}
                    src="/images/download2.png"
                    alt=""
                  />
                </a> */}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
